import { parentThemes } from "@theme/config/theme.config";

const environmentTypes = {
  FGF: 'FGF',
  NIM: 'NIM'
}

export const getEnvironmentType = (parentTheme) => {
  switch(parentTheme) {
    case parentThemes.fgfParent:
    case parentThemes.defaultFallback:
      return environmentTypes.FGF;
    case parentThemes.nimbleParent:
      return environmentTypes.NIM;
    default:
      return environmentTypes.FGF;
  }
}

