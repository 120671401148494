import { useSelector } from "react-redux";
import { themes } from "@theme/config/theme.config";
import Loader from "./index";
import React from "react";

export const LoadingStubber = ({children}) => {
  const themeName = useSelector(state => state.StartUp.theme.themeName);
  const { isFetchingPrefill } = useSelector(state => state.loanForm);

  if (themeName === themes.loading.themeName || isFetchingPrefill) {
    return <Loader />;
  }

  return <>{children}</>;
};