import {stringIsNotNullOrEmpty} from "../../utils/helpers/stringHelper";
import { toLowerObjectKeys } from "../../utils/helpers/objectHelper";

const reduceValueUsingKeyMapping = payload => (accumulator, queryStringModifier) => {
  const { queryString, modifier } = queryStringModifier;
  const queryStringValue = payload[queryString.toLowerCase()];
  if (stringIsNotNullOrEmpty(queryStringValue)) {
    return modifier(accumulator, queryStringValue);
  }

  return accumulator;
};

const createKeyMapping = (queryString, loanObjectKey) => {
  return {
    queryString,
    modifier: (loanObject, queryStringValue) => {
      return {
        ...loanObject,
        [loanObjectKey || queryString]: queryStringValue
      };
    }
  }
};

const createYearlyIncomeMapping = () => {
  const incomeFrequency = "Monthly";
  const yearlyIncomeToMonthly = (yearlyIncomeString) => {
    const yearlyIncome = parseFloat(yearlyIncomeString);
    
    if (isNaN(yearlyIncome)) {
      return "";
    }
    
    const monthlyIncome = yearlyIncome / 12;
    return "" + monthlyIncome;
  }
  
  return {
    queryString: "yearlyIncome",
    modifier: (loanObject, queryStringValue) => {
      return {
        ...loanObject,
        incomeFrequency,
        totalIncome: yearlyIncomeToMonthly(queryStringValue),
      };
    }
  }
};

// To enable Prefilling, you must import usePendingPrefill from hooks and call usePendingPrefill(name) in the component function.
// Check out PersonalLoan/components/maritalStatus/maritalStatus.js as an example of a prefilled Select input
// Check out PersonalLoan/components/firstName/firstName.js as an example of a prefilled Text input
export const queryStringToLoanObjectMapper = queryStringKeyValuePairDictionary => {
  const keyMappers = [
    createKeyMapping("reasonForLoan", "loanReason"),
    createKeyMapping("loanType", "requestedProductType"),
    createKeyMapping("requestedProductType"),
    createKeyMapping("email", "emailAddress"),
    createKeyMapping("firstName"),
    createKeyMapping("title"),
    createKeyMapping("lastName"),
    createKeyMapping("mobileNumber", "mobilePhone"),
    createKeyMapping("loanAmount"),
    createKeyMapping("unitNumber"),
    createKeyMapping("streetNumber"),
    createKeyMapping("street"),
    createKeyMapping("state"),
    createKeyMapping("suburb"),
    createKeyMapping("postCode"),
    createKeyMapping("dateOfBirth"),
    createKeyMapping("residentialStatus"),
    createKeyMapping("employmentBasis"),
    createKeyMapping("maritalStatus"),
    createKeyMapping("marketingConsent"),
    createKeyMapping("brokerFlowId", "illionBrokerFlowDocumentId"),
    createYearlyIncomeMapping(),
  ];

  return keyMappers.reduce(reduceValueUsingKeyMapping(toLowerObjectKeys(queryStringKeyValuePairDictionary)), {});
};
