export const domainSpecificConfig = {
  development: {
    FGF: {
      apiManagement: 'https://fairgo-dev-api-management.azure-api.net',
      ocpApimSubscriptionKey: 'a29d255e5fb146b19cd2476ef38323a4',
      googleApiKey: 'AIzaSyAiFDIfMP9MD6P-Jdfe-_DbEgshGNtOu7M',
      leadMarketAffiliateCode: 'YNY23K55',
      leadMarketMode: 'test',
      bankStatementIframeUrl: 'https://bs.fgfdev.com.au',
      shouldUseAsyncQueue: 'dis-enabled',
      portalUrl: 'https://portal.fgfdev.com.au',
      privacyPolicyPageFSA: 'https://website.fgfdev.com.au/legal-and-compliance/fintech-services-privacy-policy',
      contentManagementUrl: 'https://cds.fgfdev.com.au',
      contentToken: '17bc8584ecaaa3a3defff4d67fd13fafbd0029ebb127feb4917b7d421761e7d5957a5432403a106ff29f8bd31f29518ad4fe9b66b1a58b55cbcc141836a3c102c06a43b1d7550f5590c487a9bffe53517aed3da07b96165f276d59b81b16095b2026d55dc2c594ea0cc5544128cd9302b7e42bc58bcc324378b2d06ead5e77b0',
      trustPilotDataId: '5406e65db0d04a09e042d5fc',
      trustPilotBusinessUnit: '591557fc0000ff0005a24dfc',
      trustPilotURL: 'https://uk.trustpilot.com/review/fairgofinance.com.au',
      gtmId: 'GTM-MMDH47J'
    },
    NIM: {
      apiManagement: 'https://fairgo-dev-api-management.azure-api.net',
      ocpApimSubscriptionKey: 'a29d255e5fb146b19cd2476ef38323a4',
      googleApiKey: 'AIzaSyAiFDIfMP9MD6P-Jdfe-_DbEgshGNtOu7M',
      leadMarketAffiliateCode: 'YNY23K55',
      leadMarketMode: 'test',
      bankStatementIframeUrl: 'https://bs.fgfdev.com.au',
      shouldUseAsyncQueue: 'dis-enabled',
      portalUrl: 'https://portal.fgfdev.com.au',
      privacyPolicyPageFSA: 'https://website.fgfdev.com.au/legal-and-compliance/fintech-services-privacy-policy',
      contentManagementUrl: 'https://cds.fgfdev.com.au',
      contentToken: '17bc8584ecaaa3a3defff4d67fd13fafbd0029ebb127feb4917b7d421761e7d5957a5432403a106ff29f8bd31f29518ad4fe9b66b1a58b55cbcc141836a3c102c06a43b1d7550f5590c487a9bffe53517aed3da07b96165f276d59b81b16095b2026d55dc2c594ea0cc5544128cd9302b7e42bc58bcc324378b2d06ead5e77b0',
      trustPilotDataId: '5406e65db0d04a09e042d5fc',
      trustPilotBusinessUnit: '591557fc0000ff0005a24dfc',
      trustPilotURL: 'https://uk.trustpilot.com/review/fairgofinance.com.au',
      gtmId: 'GTM-KR92XT'
    }
  },
  test: {
    FGF: {
      apiManagement: 'https://apim.fgftest.com.au',
      ocpApimSubscriptionKey: '059830fb771b41259189a1e584d7eb84',
      googleApiKey: 'AIzaSyAiFDIfMP9MD6P-Jdfe-_DbEgshGNtOu7M',
      leadMarketAffiliateCode: 'YNY23K55',
      leadMarketMode: 'test',
      bankStatementIframeUrl: 'https://bs.fgftest.com.au',
      shouldUseAsyncQueue: 'disabled',
      portalUrl: 'https://portal.fgftest.com.au',
      privacyPolicyPageFSA: 'https://website.fgftest.com.au/legal-and-compliance/privacy-policy',
      contentManagementUrl: 'https://cds.fgftest.com.au',
      contentToken: '8526cc6182773c17f4FGF_fec22e48b88fc14adb48e8a7c231e36196900d3ff191412b754a235e478611f9099cefb804db8982d5c01400534171c6172235ef855cd93ef085c0e07cccd12fca1a3114d688cd0d7e0bc59a616e0bb896c491baad311faccaee1ba1fbfbe347046e1e79ef2de8c0b1aff03392c6e0d40a74a184049fae',
      trustPilotDataId: '',
      trustPilotBusinessUnit: '',
      trustPilotURL: '',
      REVIEW_ON_MY_WEBSITE_GOOGLE_BADGE_TOKEN: 'jqHoMHzhg401SUZpiY1oBM9ZkIlsYC1UOZqtPXGZQHozjaDETj',
      REVIEW_ON_MY_WEBSITE_TRUSTPILOT_BADGE_TOKEN: 'hSA7LCKnbFx25OWuk8IaUnRTNzgaSXWB06RzaEMcqmgw7qT1BS',
      gtmId: 'GTM-MMDH47J'
    },
    NIM: {
      apiManagement: 'https://apim.fgftest.com.au',
      ocpApimSubscriptionKey: '059830fb771b41259189a1e584d7eb84',
      googleApiKey: 'AIzaSyAiFDIfMP9MD6P-Jdfe-_DbEgshGNtOu7M',
      leadMarketAffiliateCode: 'YNY23K55',
      leadMarketMode: 'test',
      bankStatementIframeUrl: 'https://bs.fgftest.com.au',
      shouldUseAsyncQueue: 'disabled',
      portalUrl: 'https://portal.fgftest.com.au',
      privacyPolicyPageFSA: 'https://website.fgftest.com.au/legal-and-compliance/privacy-policy',
      contentManagementUrl: 'https://cds.fgftest.com.au',
      contentToken: '8526cc6182773c17f4FGF_fec22e48b88fc14adb48e8a7c231e36196900d3ff191412b754a235e478611f9099cefb804db8982d5c01400534171c6172235ef855cd93ef085c0e07cccd12fca1a3114d688cd0d7e0bc59a616e0bb896c491baad311faccaee1ba1fbfbe347046e1e79ef2de8c0b1aff03392c6e0d40a74a184049fae',
      trustPilotDataId: '',
      trustPilotBusinessUnit: '',
      trustPilotURL: '',
      REVIEW_ON_MY_WEBSITE_GOOGLE_BADGE_TOKEN: 'jqHoMHzhg401SUZpiY1oBM9ZkIlsYC1UOZqtPXGZQHozjaDETj',
      REVIEW_ON_MY_WEBSITE_TRUSTPILOT_BADGE_TOKEN: 'hSA7LCKnbFx25OWuk8IaUnRTNzgaSXWB06RzaEMcqmgw7qT1BS',
      gtmId: 'GTM-KR92XT'
    }
  },
  prod: {
    FGF: {
      apiManagement: 'https://apim.fairgofinance.com.au',
      ocpApimSubscriptionKey: 'c9372a739245435dbb5c8815e1d02b24',
      googleApiKey: 'AIzaSyAiFDIfMP9MD6P-Jdfe-_DbEgshGNtOu7M', // fairgofinanceautomation@gmail.com
      leadMarketAffiliateCode: '4PJ8Z84Z',
      leadMarketMode: 'production',
      bankStatementIframeUrl: 'https://bs.fairgofinance.com.au',
      shouldUseAsyncQueue: 'disabled',
      portalUrl: 'https://portal.fairgofinance.com.au',
      privacyPolicyPageFSA: 'https://www.fairgofinance.com.au/legal-and-compliance/fintech-services-privacy-policy',
      contentManagementUrl: 'https://cds.fairgofinance.com.au',
      contentToken: 'd2c30e0f3153c199f6FGF_343f0f83c873ae537fcc64e339afd78098cd49625d1000c16c616ab0bcf1cf47aae6711c484340ebe759627f5cbf67f8df87de1f8802809e856fabc84609528da872b5f0e296e7840662eab414516f09d121b8565d721d293c2fe71104c96f631dbb472a54281d2c880b796c38bb844649caf784fe31c9',
      trustPilotDataId: '5406e65db0d04a09e042d5fc',
      trustPilotBusinessUnit: '591557fc0000ff0005a24dfc',
      trustPilotURL: 'https://uk.trustpilot.com/review/fairgofinance.com.au',
      gtmId: 'GTM-MMDH47J'
    },
    NIM: {
      apiManagement: 'https://apim.fairgofinance.com.au',
      ocpApimSubscriptionKey: 'c9372a739245435dbb5c8815e1d02b24',
      googleApiKey: 'AIzaSyAiFDIfMP9MD6P-Jdfe-_DbEgshGNtOu7M', // fairgofinanceautomation@gmail.com
      leadMarketAffiliateCode: '4PJ8Z84Z',
      leadMarketMode: 'production',
      bankStatementIframeUrl: 'https://bs.fairgofinance.com.au',
      shouldUseAsyncQueue: 'disabled',
      portalUrl: 'https://portal.fairgofinance.com.au',
      privacyPolicyPageFSA: 'https://www.fairgofinance.com.au/legal-and-compliance/fintech-services-privacy-policy',
      contentManagementUrl: 'https://cds.fairgofinance.com.au',
      contentToken: 'd2c30e0f3153c199f6FGF_343f0f83c873ae537fcc64e339afd78098cd49625d1000c16c616ab0bcf1cf47aae6711c484340ebe759627f5cbf67f8df87de1f8802809e856fabc84609528da872b5f0e296e7840662eab414516f09d121b8565d721d293c2fe71104c96f631dbb472a54281d2c880b796c38bb844649caf784fe31c9',
      trustPilotDataId: '5406e65db0d04a09e042d5fc',
      trustPilotBusinessUnit: '591557fc0000ff0005a24dfc',
      trustPilotURL: 'https://uk.trustpilot.com/review/fairgofinance.com.au',
      gtmId: 'GTM-KR92XT'
    }
  }
} as const;