import { Config } from "./configType";

export const fgfConfig: Config = {
  phone: '1300 324 746',
  email: 'info@fairgofinance.com.au',
  acn: '134 369 574',
  acl: '387995',
  compliance: '',
  faq: 'https://www.fairgofinance.com.au/how-it-works/frequently-asked-questions/',
  companyName: 'Fair Go Finance',
  termsAndConditions: 'https://www.fairgofinance.com.au/legal-and-compliance/terms-and-conditions-of-use/',
  privacy: 'https://www.fairgofinance.com.au/legal-and-compliance/privacy-policy',
  partnerLogo: 'fairgo-partnership-logo.svg',
  useChatbot: true
};