import { all, call, takeLatest, put, takeEvery, select } from "redux-saga/effects";
import actions from "./actions";
import { getLoanReasons } from "../../services/personalLoanApi";
import { WENT_WRONG_MESSAGE } from "../../constants/commonMessage";
import { getThemeFromQuerystring, getParentThemeFromHost, determineTheme } from "@theme/config/theme.config";
import { nimbleFavicon } from "src/config/nimble";

const createGetterSaga = (stateName, getter, mapper) => {
  return function*() {
    try {
      const response = yield call(getter);
      const { status, data } = response;
      const { value } = data;
      if (status === 200) {
        const values = (mapper || ((v) => v))(value || []);
        yield put(actions.getSuccess({stateName, values}));
      } else {
        yield put(actions.getError({ stateName, errMessage: WENT_WRONG_MESSAGE }));
      }
    } catch (error) {
      yield put(
        actions.getError({
          stateName,
          errMessage: error.message || WENT_WRONG_MESSAGE
        })
      );
    }
  }
}

function* setInitialTheme(action) {
  const { payload } = action;
  const host = window.location.hostname.toLocaleLowerCase();
  const parentTheme = getParentThemeFromHost(host);
  if (parentTheme === 'nimbleParent') {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = nimbleFavicon;
  }
  const queryTheme = getThemeFromQuerystring(payload, parentTheme);
  const theme = determineTheme(parentTheme, queryTheme);
  yield put(actions.changeTheme({theme, parentTheme, queryTheme}));
}

const getParentTheme = (state) => state.StartUp.parentTheme;

function* handleQueryThemeChange(action) {
  const queryTheme = action.payload;
  const parentTheme = yield select(getParentTheme);

  const theme = determineTheme(parentTheme, queryTheme);
  yield put(actions.changeTheme({theme, parentTheme, queryTheme}));
}

function* watchQueryThemeChange() {
  yield takeEvery(actions.CHANGE_QUERY_THEME,  handleQueryThemeChange);
}

export const __TEST__ = {
  setInitialTheme,
};

const loanReasons = createGetterSaga("loanReasons", getLoanReasons);

export default function* rootSaga() {
  yield all([
    watchQueryThemeChange(),
    takeLatest(actions.GET_LOAN_REASONS_START, loanReasons),
    takeLatest(actions.INITIALISE_QUERY_STRING, setInitialTheme)
  ]);
}
