import { createPayloadAction, createEmptyAction } from "../utility/actions";
import { parseQueryString as queryStringParser } from "../../services/parseQueryString";

const START_UP = "START_UP";
const GET_LOAN_REASONS_START = "GET_LOAN_REASONS_START";
const GET_SUCCESS = "GET_SUCCESS";
const GET_ERROR = "GET_ERROR";
const INITIALISE_QUERY_STRING = "INITIALISE_QUERY_STRING";
const FILL_FORM = "FILL_FORM";
const CHANGE_THEME = "CHANGE_THEME";
const CHANGE_THEME_BACKEND = "CHANGE_THEME_BACKEND"
const CHANGE_QUERY_THEME="CHANGE_QUERY_THEME";
const parseQueryString = () => {
  return {
    type: INITIALISE_QUERY_STRING,
    payload: queryStringParser(window.location.search || "")
  };
};

export const actions = {
  START_UP,
  GET_LOAN_REASONS_START,
  INITIALISE_QUERY_STRING,
  FILL_FORM,
  CHANGE_THEME,
  CHANGE_QUERY_THEME,
  CHANGE_THEME_BACKEND,
  GET_SUCCESS,
  GET_ERROR,
  startUp: createPayloadAction(START_UP),
  getLoanReasonsRequest: createEmptyAction(GET_LOAN_REASONS_START),
  getSuccess: createPayloadAction(GET_SUCCESS),
  getError: createPayloadAction(GET_ERROR),
  parseQueryString: parseQueryString,
  fillForm: createEmptyAction(FILL_FORM),
  changeTheme: createPayloadAction(CHANGE_THEME),
  changeThemeBackend: createPayloadAction(CHANGE_THEME_BACKEND),
  changeQueryTheme: createPayloadAction(CHANGE_QUERY_THEME),
};

export default actions;
