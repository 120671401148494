import defaultTheme from "./default";
import rentBondTheme from "./rentBond";
import clearScoreTheme from "./clearScore";
import overflowTheme from "./overflow";
import yesLoansTheme from "./yesLoans";
import loanOptionsTheme from "./loanOptions"
import nimbleTheme from "./nimble";
import { fgfConfig } from "../../config/fgf";
import { nimbleConfig } from "../../config/nimble";

const fgfParent = {
  default: {
    themeName: "fgfTheme",
    file: defaultTheme,
    configFile: fgfConfig
  },
  yesLoans: {
    themeName: "yesLoansTheme",
    file: yesLoansTheme,
    configFile: fgfConfig
  },
  rent: {
    themeName: "rentBondTheme",
    file: rentBondTheme,
    configFile: fgfConfig
  },
  clearScore: {
    themeName: "clearScoreTheme",
    file: clearScoreTheme,
    configFile: fgfConfig
  },
  overflow: {
    themeName: "overflowTheme",
    file: overflowTheme,
    configFile: fgfConfig
  },
  loanOptions : {
    themeName: "loanOptionsTheme",
    file: loanOptionsTheme,
    configFile: fgfConfig
  },
} as const;

export type FgfParent = typeof fgfParent;

export const nimbleParent = {
  default: {
    themeName: "nimbleTheme",
    file: nimbleTheme,
    configFile: nimbleConfig
  },
  zeefi: {
    themeName: "zeefiTheme",
    file: defaultTheme,
    configFile: nimbleConfig
  },
} as const;

export type NimbleParent = typeof nimbleParent;

export const defaultFallback = {
  themeName: "defaultFallbackTheme",
  file: defaultTheme,
  configFile: fgfConfig
} as const;

export type DefaultFallback = typeof defaultFallback;
export type DefaultFallbackThemeName = typeof defaultFallback['themeName'];

const loading = {
  themeName:"loading",
  file: {},
  configFile: {}
} as const;

export type Loading = typeof loading;
export type LoadingThemeName = typeof loading['themeName'];

export const themes = {
  fgfParent,
  nimbleParent,
  defaultFallback,
  loading
} as const

export type Themes = typeof themes;

export type Theme = {
  themeName: ThemeNames;
  file: {};
  configFile: {};
};
  
export type ParentThemes = keyof typeof themes;
export const parentThemes: ParentThemes[] = Object.keys(themes) as ParentThemes[];

export type NestedKeys<T extends ParentThemes> = keyof typeof themes[T];

export type FgfParentKeys = NestedKeys<'fgfParent'>;
export const fgfParentKeys: FgfParentKeys[] = Object.keys(themes.fgfParent) as FgfParentKeys[];

export type NimbleParentKeys = NestedKeys<'nimbleParent'>;
export const nimbleParentKeys: NimbleParentKeys[] = Object.keys(themes.nimbleParent) as NimbleParentKeys[];

export type QueryThemes = NimbleParentKeys | FgfParentKeys
export const queryThemes = [...new Set([...fgfParentKeys, ...nimbleParentKeys])]

export type ThemeNames = 
  | FgfParent[keyof typeof fgfParent]['themeName']
  | NimbleParent[keyof typeof nimbleParent]['themeName']
  | DefaultFallback['themeName']
  | Loading['themeName'];

export type FgfThemes = FgfParent[keyof typeof fgfParent]['themeName'];
export const fgfThemes: Array<FgfThemes> = [themes.fgfParent.default.themeName, themes.fgfParent.rent.themeName, themes.fgfParent.clearScore.themeName, themes.fgfParent.overflow.themeName, themes.fgfParent.yesLoans.themeName]
export const fgfChildThemes: Array<FgfThemes> = [themes.fgfParent.rent.themeName, themes.fgfParent.clearScore.themeName, themes.fgfParent.overflow.themeName, themes.fgfParent.yesLoans.themeName]

export type NimbleThemes = NimbleParent[keyof typeof nimbleParent]['themeName'];
export const nimbleThemes: Array<NimbleThemes> = [themes.nimbleParent.default.themeName, themes.nimbleParent.zeefi.themeName]
export const nimbleChildThemes: Array<NimbleThemes> = [themes.nimbleParent.zeefi.themeName]


export const isParentTheme = (state) => state.StartUp.queryTheme === 'default' || !state.StartUp.queryTheme;

const foo: Theme = {
  themeName: "clearScoreTheme",
  file: {},
  configFile: {}
}

export const determineTheme = (parentTheme: ParentThemes, queryTheme?: QueryThemes): Theme => {
  if (parentTheme === "loading") {
    return themes["loading"];
  }
  if (parentTheme === "defaultFallback") {
    return themes["defaultFallback"];
  }
  if (Object.keys(themes).includes(parentTheme)) {
    if (queryTheme) {
      const queryThemeLowerCase = queryTheme.toLowerCase();
      const childThemes = Object.keys(themes[parentTheme]);
      if (childThemes.map(v => v.toLowerCase()).includes(queryThemeLowerCase)) {
        for(let i = 0; i < childThemes.length; i++) {
          const childTheme = childThemes[i];
          if (queryThemeLowerCase === childTheme.toLowerCase()) {
            return themes[parentTheme][childTheme];
          }
        }
      }
    }
    return themes[parentTheme]["default"];
  }
  return themes["defaultFallback"];
};

const hasNonEmptyHost = (host?: string): host is string => {
  return (!!host && host.length > 0);
}
const isNimbleTheme = (host: string): host is NimbleThemes => {
  return host.includes('nimble'); 
}
const isFgfTheme = (host: string): host is FgfThemes => {
  return host.includes('fairgofinance') || host.includes('fgf');
}

const determineParentTheme = (host: string): ParentThemes => {
  if (isNimbleTheme(host)) {
    return 'nimbleParent';
  }
  if (isFgfTheme(host)) {
    return 'fgfParent';
  } 
  return 'defaultFallback';
}

export const getParentThemeFromHost = (host?: string): ParentThemes => {
  const hasHost = hasNonEmptyHost(host);
  if (hasHost) {
    return determineParentTheme(host);
  }
  return 'defaultFallback';
};

type QueryString = {theme?: string}
export const getThemeFromQuerystring = (queryString: QueryString) => {
  return queryString.theme;
};
