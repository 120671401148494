import { all, call, put, take, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import startupActions from "../startup/actions";
import { getLoanForm, postLoanForm } from "../../services/personalLoanApi";
import { WENT_WRONG_MESSAGE } from "../../constants/commonMessage";
import { sanitizeValues } from "../../utils/helpers/sanitizeLoanObjectValues";
import { queryStringToLoanObjectMapper } from "../utility/queryStringToLoanObjectMapper";
import { LOAN_STATUS, STEPS_TOTAL } from "../../constants/commonConstants";
import { stringifyQueryStringObject } from "../../services/parseQueryString";
import { gaDataLayerPush, getGoogleUserId } from "../../utils/helpers/googleAnalytics";
import { prepareLoanForm } from "../../services/prepareLoanForm";

function pushApplicationStartAnalyticsEvent(loanFormPost) {
  const {emailAddress, loanAmount, loanReason} = loanFormPost;
  gaDataLayerPush(emailAddress, "applicationStart", {loanAmount, loanReason})
}

function* putAllowedToProceed(applicationResponse) {
  const {allowedToProceed, message, action} = applicationResponse;
  
  yield put(actions.setAllowedToProceed({
    allowedToProceed,
    message,
    action,
  }));
}

function* loanForm(action) {
  const { payload } = action;
  const { values, step, queryString } = payload;
  const { useQueue, ...restQueryString } = queryString;
  const search = stringifyQueryStringObject(restQueryString);
  const loanFormPost = prepareLoanForm({values, step});
  try {
    const pageOneStep = 1;
    if (step === pageOneStep) {
      pushApplicationStartAnalyticsEvent(loanFormPost);
    }
    const googleUserId = getGoogleUserId();
    const response = yield call(postLoanForm, loanFormPost, search, googleUserId, useQueue);
    const { status, data } = response;

    switch (status) {
      case 200:
        const { value = {}}  = data;
        const finalPage = STEPS_TOTAL;
        const isFormCompleted = step === finalPage && data.status === LOAN_STATUS.CREATED;
        yield put(actions.postLoanFormSuccess({ data, isFormCompleted }));
        yield putAllowedToProceed(value);

        if (data.status !== LOAN_STATUS.DECLINED && data.status !== LOAN_STATUS.DECLINED_IN_PROGRESS) {
          yield put(actions.setStep(step + 1));
        }

        break;
      default: {
        yield put(
          actions.postLoanFormError({ errMessage: WENT_WRONG_MESSAGE })
        );
      }
    }
  } catch (error) {
    yield put(
      actions.postLoanFormError({
        errMessage: error.message || WENT_WRONG_MESSAGE
      })
    );
  }
}

function* prefillFormOnAction({ payload }) {
  
  yield prefillForm({payload});
}

const stringToBool = (payload) => {
  for (const property in payload) {
    if (payload[property] === "true") {
      payload[property] = true;
    }
    if (payload[property] === "false") {
      payload[property] = false;
    }
  }
}

function* prefillForm({ payload }) {
  const { id } = payload;
  const hasQueryStringItems = Object.keys(payload).length !== 0;
  const hasId = id && id.trim() !== "";
  
  const sanitizedLoanObject = sanitizeValues(queryStringToLoanObjectMapper(payload));
  stringToBool(sanitizedLoanObject);
  yield put(actions.setSanitizedQueryStringLoanObject(sanitizedLoanObject));

  yield take(startupActions.FILL_FORM); // not all pages want prefill to run
  if (hasQueryStringItems && !hasId) {
    yield put(actions.mergeSanitizedPendingFields(sanitizedLoanObject));
  } else if (hasId) {
    yield call(prefillFormUsingId, id);
  }

  yield put(actions.setHasFetchedLoanForm());
}

function* prefillFormUsingId(id) {

  yield put(actions.prefillStart());
  let response = {}; 
  try{
    const prefix = "loanapplication-";
    const prefixedId = id.startsWith(prefix) ? id : prefix + id;
    response = yield call(getLoanForm, prefixedId);
  } catch (e) {
    response = e.response;
  }
  const { status, data } = response;
  const { code, value } = data;
  const { accountState } = value || "";
  
  if (status === 200 && code === 200) {
    // TODO: Discuss with Cam
    yield put(startupActions.changeQueryTheme(value.theme));
    // yield put(startupActions.changeThemeBackend(value.theme));
    yield put(actions.prefillUsingPayload(value));
    yield put(actions.prefillSuccess());
    
    if (accountState === "Declined") {
      yield put(actions.prefillError(LOAN_STATUS.NOT_RETRIEVABLE));
    }
  }
  else {
    const loanStatus = data.status;
    yield put(actions.prefillError(loanStatus));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.POST_LOAN_FORM_START, loanForm),
    takeLatest(startupActions.INITIALISE_QUERY_STRING, prefillFormOnAction),
  ]);
}

export const __TEST__ = {
  prefillForm,
  prefillFormUsingId,
};
