import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { determineTheme } from "@theme/config/theme.config";

export default function AppProvider({ children }) {
  const { useChatbot } = useSelector((state) => state.StartUp.theme.configFile);
  const showOrHideChatbot = useChatbot ? "show" : "hide";
  window.zE("webWidget", showOrHideChatbot);

  const themeName = useSelector((state) => {
    return {
      parentTheme: state.StartUp.parentTheme,
      queryTheme: state.StartUp.queryTheme,
    };
  });
  const { parentTheme, queryTheme } = themeName;
  const theme = determineTheme(parentTheme, queryTheme);
  return <ThemeProvider theme={theme.file}>{children}</ThemeProvider>;
}
