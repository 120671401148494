import { domainSpecificConfig } from "../config/domainSpecificConfig";
import { getParentThemeFromHost } from "@theme/config/theme.config";
import { getEnvironmentType } from "../utils/helpers/getEnvironmentType";

const config = window.__APPFORM__.config;

const host = window.location.hostname.toLowerCase();
const parentTheme = getParentThemeFromHost(host);
const environmentType = getEnvironmentType(parentTheme);


const environmentSettings = {
  runConfiguration: config.REACT_APP_ENVIRONMENT_RUN_CONFIGURATION,
  ...domainSpecificConfig[config.REACT_APP_ENVIRONMENT_RUN_CONFIGURATION][environmentType]
};

export default environmentSettings;