import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TagManager from 'react-gtm-module'
import { removeSearch, combineHashSearchQueryString, stringifyQueryStringObject } from "./services/parseQueryString";
import environmentSettings from './constants/environmentSettings';

const resumptionRedirect = () => {
    if (window.location.pathname === "/continueapplication.ashx") {
        window.location = "/apply" + window.location.search;
    }
}

const hashRedirect = () => {
    if (!!window.location.hash) {
        const hashWithoutHash = window.location.hash.substring(1);
        const hashWithoutSearch = removeSearch(hashWithoutHash);
        const combinedQueryString = combineHashSearchQueryString(window.location);
        window.location = hashWithoutSearch + stringifyQueryStringObject(combinedQueryString);
    }
}

const tagManagerArgs = {
    gtmId: environmentSettings.gtmId
}

TagManager.initialize(tagManagerArgs);

resumptionRedirect();
hashRedirect();

ReactDOM.render(<App />, document.getElementById('root'));